"use client";

import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useUpdateUserMutation } from "@/features/user/userInjection";
import { useSession } from "next-auth/react";
import React, { useEffect, useState } from "react";

const ConsentPopUp = () => {
	const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();
	const [show, setShow] = useState(false);
	const { data: session, update: updateSession } = useSession();

	useEffect(() => {
		if (session) {
			setShow(!session.user.isConsentAccepted);
		}
	}, [session]);

	const handleAcceptTerms = () => {
		try {
			updateUser({
				userId: session?.user?.id || "",
				patchUser: {
					isConsentAccepted: true,
				},
			});
			updateSession({
				...session,
				user: {
					...session?.user,
					isConsentAccepted: true,
				},
			});
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<Dialog open={show}>
			<DialogTrigger asChild>
				<div />
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="font-semibold">
						Accept New Terms & Conditions
					</DialogTitle>
				</DialogHeader>

				<div className="flex w-full items-center justify-center">
					Please note: The terms and conditions have been updated. Kindly accept the new
					terms to continue using the web app.
				</div>
				<DialogFooter>
					<div className="flex w-full items-center justify-center">
						<DialogClose asChild className="w-1/2">
							<Button onClick={handleAcceptTerms}>Accept Terms</Button>
						</DialogClose>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ConsentPopUp;
